import React from 'react';
import { Routes, Route } from 'react-router';
import { BrowserRouter } from 'react-router-dom';
import { JobPage } from '.';
import { AccountIntegration } from '../../entities/AccountIntegration';
import { JobPageTab } from './JobPageTab';

interface PropTypes {
  atsSignableOfferEnabled: boolean;
  jobBoardEnabled: boolean;
  duplicateJobEnabled: boolean;
  standardNpsCalculation: boolean;
  emailAccountIntegration: AccountIntegration;
  jobPipelineTriggerIconEnabled: boolean;
  applyAndScheduleLinkEnabled: boolean;
  selfSchedulingLinkOptionsEnabled: boolean;
}

function JobPageTabRouter(props: PropTypes & { activeTab: JobPageTab }) {
  return (
    <JobPage
      activeTab={props.activeTab}
      atsSignableOfferEnabled={props.atsSignableOfferEnabled}
      emailAccountIntegration={props.emailAccountIntegration}
      jobBoardEnabled={props.jobBoardEnabled}
      duplicateJobEnabled={props.duplicateJobEnabled}
      standardNpsCalculation={props.standardNpsCalculation}
      applyAndScheduleLinkEnabled={props.applyAndScheduleLinkEnabled}
      selfSchedulingLinkOptionsEnabled={props.selfSchedulingLinkOptionsEnabled}
    />
  );
}

export default function JobPageRouter(props: PropTypes) {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={'jobs'}>
          <Route
            path={':id/overview'}
            element={<JobPageTabRouter {...props} activeTab='Overview' />}
          />
          <Route
            path={':id/pipeline'}
            element={<JobPageTabRouter {...props} activeTab='Pipeline' />}
          />
          <Route
            path={':id/job_boards'}
            element={<JobPageTabRouter {...props} activeTab='Job Boards' />}
          />
          <Route
            path={':id'}
            element={<JobPageTabRouter {...props} activeTab='Overview' />}
          />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
