import React, { useState } from 'react';
import { GenericEntry } from '..';
import { Application } from '../../../../entities/Application';
import { SignableOfferFeed } from '../../../../entities/ApplicationFeed';
import { User } from '../../../../entities/User';
import { AuthorName } from '../AuthorName';
import { AlertObject } from '../../../../components/Alert';
import { Button } from 'reactstrap';
import { RevokeSignableOfferModal } from './RevokeSignableOfferModal';
import moment from 'moment';
import { SpanTooltip } from '../../../../components/SpanTooltip';
import { SignableOfferSummaryModal } from '../../ProfileTab/SignableOfferPanel/SignableOfferSummaryModal';

interface PropTypes {
  application: Application;
  currentUser: User;
  entry: SignableOfferFeed;
  isLastItemInPage: boolean;
  setAlert: (data: AlertObject) => void;
  reloadApplication: () => void;
  reloadFeed: () => void;
}

function isOfferDisabled(status: string) {
  if (status === 'pending') {
    return true;
  }

  return false;
}

function ButtonWrapper(props: {
  disabled?: boolean;
  setModalOpen: (flag: boolean) => void;
}) {
  return (
    <Button
      disabled={props.disabled}
      onClick={() => props.setModalOpen(true)}
      color='borderless'
    >
      View Offer
    </Button>
  );
}

function OfferActions(
  props: PropTypes & { setRevokeModalOpen: (open: boolean) => void },
) {
  if (props.entry.signableOfferId == null) {
    return null;
  }

  const [modalOpen, setModalOpen] = useState(false);
  const disableOfferAction = isOfferDisabled(props.entry.signableOfferStatus);

  return (
    <div className='d-flex mt-3 gap-3'>
      <SignableOfferSummaryModal
        isOpen={modalOpen}
        signableOfferId={props.entry.signableOfferId}
        onClose={() => setModalOpen(false)}
      />
      {disableOfferAction ? (
        <SpanTooltip
          className='text-center'
          text={<ButtonWrapper disabled={true} setModalOpen={setModalOpen} />}
          tooltipText='The offer submission is processing'
        />
      ) : (
        <ButtonWrapper setModalOpen={setModalOpen} />
      )}
      {props.entry.revokeSignableOfferPermitted && (
        <Button
          size='md'
          color='danger'
          onClick={() => props.setRevokeModalOpen(true)}
        >
          Revoke Offer
        </Button>
      )}
    </div>
  );
}

function ExpiresDate(props: { date?: Date }) {
  if (!props.date) {
    return <>.</>;
  }

  return (
    <span>
      {' '}
      that expires on the{' '}
      <strong>{moment(props.date).format('MMMM D, YYYY')}.</strong>
    </span>
  );
}

export function SignableOffer(props: PropTypes) {
  const [revokeModalOpen, setRevokeModalOpen] = useState(false);

  return (
    <>
      <RevokeSignableOfferModal
        id={props.entry.signableOfferId}
        isOpen={revokeModalOpen}
        setAlert={props.setAlert}
        reloadApplication={props.reloadApplication}
        setIsOpen={setRevokeModalOpen}
      />
      <GenericEntry
        icon='bi-envelope'
        iconClassName='bg-blue-gray-light'
        title={
          <>
            <AuthorName name={props.entry.authorName} /> sent{' '}
            <strong>{props.application.candidate.name}</strong> an offer
            <ExpiresDate date={props.entry.signableOfferExpiresAt} />
          </>
        }
        date={props.entry.createdAt}
        {...props}
      >
        <OfferActions {...props} setRevokeModalOpen={setRevokeModalOpen} />
      </GenericEntry>
    </>
  );
}
