import { useEffect, useState } from 'react';
import React from 'react';
import { LoadingSpinner } from '../../../../../../components/LoadingSpinner';
import { Col, Row } from 'reactstrap';
import { SalaryInput } from './SalaryInput';
import { SignableOfferForm } from '../..';
import { EmptyState } from '../../../../../../components/EmptyState';
import { SignableOfferTemplate } from '../../../../../../entities/v1/applicant_tracking/SignableOfferTemplate';
import { isFormValid } from './formValidator';
import { StringInput } from './StringInput';
import { NumericInput } from './NumericInput';
import { DateInput } from './DateInput';
import { humanize } from '../../../../../../utils/humanize';
import { SignableDocumentService } from '../../../../../../services/v1/applicant_tracking/SignableDocumentService';
import { VARIABLES } from '../../../variables';

interface PropTypes {
  form: SignableOfferForm;
  offerTemplate: SignableOfferTemplate;
  setForm: (form: SignableOfferForm) => void;
  setIsValid: (valid: boolean) => void;
}

function VariableSelector(props: { name: string } & PropTypes) {
  switch (props.name) {
    case 'bonus':
      return (
        <SalaryInput
          label='Bonus'
          currency={props.form.bonusCurrency}
          salary={props.form.bonus}
          setCurrency={(currency: string) =>
            props.setForm({ ...props.form, bonusCurrency: currency })
          }
          setSalary={(bonus: number) =>
            props.setForm({ ...props.form, bonus: bonus })
          }
        />
      );
    case 'candidate_name':
      return (
        <StringInput
          label='Candidate Name'
          value={props.form.candidateName}
          setValue={(value: string) =>
            props.setForm({ ...props.form, candidateName: value })
          }
        />
      );
    case 'company_name':
      return (
        <StringInput
          label='Company Name'
          value={props.form.companyName}
          setValue={(value: string) =>
            props.setForm({ ...props.form, companyName: value })
          }
        />
      );
    case 'direct_manager':
      return (
        <StringInput
          label='Direct Manager'
          value={props.form.directManager}
          setValue={(value: string) =>
            props.setForm({ ...props.form, directManager: value })
          }
        />
      );
    case 'equity':
      return (
        <NumericInput
          label='Equity'
          placeholder='Insert Equity (e.g. 1500)'
          value={props.form.equity}
          setValue={(value: number) =>
            props.setForm({ ...props.form, equity: value })
          }
        />
      );
    case 'job_title':
      return (
        <StringInput
          label='Job Title'
          value={props.form.jobTitle}
          setValue={(value: string) =>
            props.setForm({ ...props.form, jobTitle: value })
          }
        />
      );
    case 'offer_expiration_date':
      return (
        <DateInput
          date={props.form.offerExpirationDate}
          label='Offer Expiration Date'
          setDate={(date: string) =>
            props.setForm({ ...props.form, offerExpirationDate: date })
          }
        />
      );
    case 'paid_time_off':
      return (
        <NumericInput
          label='Paid Time Off'
          placeholder='Insert Time Off (days)'
          value={props.form.paidTimeOff}
          setValue={(value: number) =>
            props.setForm({ ...props.form, paidTimeOff: value })
          }
        />
      );
    case 'salary':
      return (
        <SalaryInput
          label='Salary'
          currency={props.form.salaryCurrency}
          salary={props.form.salary}
          setCurrency={(currency: string) =>
            props.setForm({ ...props.form, salaryCurrency: currency })
          }
          setSalary={(salary: number) =>
            props.setForm({ ...props.form, salary: salary })
          }
        />
      );
    case 'sender_name':
      return (
        <StringInput
          label='Sender Name'
          value={props.form.senderName}
          setValue={(value: string) =>
            props.setForm({ ...props.form, senderName: value })
          }
        />
      );
    case 'sick_days':
      return (
        <NumericInput
          label='Sick Days'
          value={props.form.sickDays}
          setValue={(value: number) =>
            props.setForm({ ...props.form, sickDays: value })
          }
        />
      );
    case 'starting_date':
      return (
        <DateInput
          date={props.form.startingDate}
          label='Starting Date'
          setDate={(date: string) =>
            props.setForm({ ...props.form, startingDate: date })
          }
        />
      );
    case 'today_date':
      return (
        <DateInput
          date={props.form.todayDate}
          label='Today Date'
          setDate={(date: string) =>
            props.setForm({ ...props.form, todayDate: date })
          }
        />
      );
    case 'working_hours':
      return (
        <NumericInput
          label='Working Hours'
          value={props.form.workingHours}
          setValue={(value: number) =>
            props.setForm({ ...props.form, workingHours: value })
          }
        />
      );
    default:
      return (
        <StringInput
          label={humanize(props.name)}
          value={props.form.customVariables?.get(props.name)}
          setValue={(value: string) =>
            props.setForm({
              ...props.form,
              customVariables: props.form.customVariables.set(
                props.name,
                value,
              ),
            })
          }
        />
      );
  }
}

export function OfferDetails(props: PropTypes) {
  const [variables, setVariables] = useState<string[]>(null);

  useEffect(() => {
    (async () => {
      const variables = await SignableDocumentService.variables(
        props.offerTemplate.signableDocuments.map((v) => v.id),
      );

      const filteredVariables = variables.documentVariables.filter(
        (dv) =>
          VARIABLES.find((v) => v.name === dv) == null ||
          VARIABLES.find((v) => v.name === dv)?.form === true,
      );

      setVariables(filteredVariables);
    })();
  }, []);

  useEffect(() => {
    props.setIsValid(isFormValid(props.form, variables));
  }, [props.form, variables]);

  if (variables == null) {
    return <LoadingSpinner />;
  }

  return variables.length > 0 ? (
    <>
      <Row className='px-5 g-4'>
        {variables.map((v, index) => (
          <Col key={index} xs='6'>
            <VariableSelector {...props} name={v} />
          </Col>
        ))}
      </Row>
    </>
  ) : (
    <EmptyState
      classNames='my-0'
      cardless
      title={'There are no variables to setup'}
    />
  );
}
