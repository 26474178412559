import { createContext, useContext } from 'react';

interface FeatureFlagContextType {
  atsCaptureEventsAhoy: boolean;
}

export const FeatureFlagContext = createContext<FeatureFlagContextType | null>(
  null,
);

export function useFeatureFlags() {
  const context = useContext(FeatureFlagContext);
  if (!context) {
    throw new Error(
      'useFeatureFlags must be used within a FeatureFlagProvider',
    );
  }
  return context;
}
