import snakecaseKeys from 'snakecase-keys';
import { ApiService } from '../../../ApiService';
import { SignableDocumentVariables } from '../../../../entities/v1/applicant_tracking/SignableDocumentVariables';
import { SignableDocumentPreview } from '../../../../entities/v1/applicant_tracking/SignableDocumentPreview';

interface PreviewRequest {
  applicationId: number;
  variables: string;
}
export class SignableDocumentService {
  public static variables(
    ids: number[] = [],
  ): Promise<SignableDocumentVariables> {
    return ApiService.get<SignableDocumentVariables>(
      `/api/v1/applicant_tracking/signable_documents/variables`,
      snakecaseKeys({ ids }),
    );
  }

  public static preview(
    id: number,
    previewRequest: PreviewRequest,
  ): Promise<SignableDocumentPreview> {
    return ApiService.get<SignableDocumentPreview>(
      `/api/v1/applicant_tracking/signable_documents/${id}/preview`,
      snakecaseKeys(previewRequest),
    );
  }
}
