import React, { useCallback, useEffect, useState } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import { Application, ApplicationStatus } from '../../entities/Application';
import { SurveyRequestService } from '../../reference_check/services/SurveyRequestService';
import { BackgroundChecksTab } from './BackgroundChecksTab';
import { CandidateTab } from './CandidateTab';
import { InterviewsTab } from './InterviewsTab';
import SuccessTab from './SuccessTab';
import { ReferenceRequestStatusModal } from './ReferenceRequestStatusModal';
import { SurveyRequest } from '../../reference_check/entities/SurveyRequest';
import { ReferencesStatus } from './ReferencesStatus';
import { ViewStatusButton } from './ViewStatusButton';
import { ExportButton } from './ExportButton';
import ReportDate from './SuccessTab/ReportDate';
import { ReferenceRequestStatus } from './ReferenceRequestStatus';
import { ReferenceRequestConfirmationModal } from './ReferenceRequestConfirmationModal';
import { ReferenceRequestConfirmationAlert } from './ReferenceRequestConfirmationAlert';
import { SharePulseSurveyModal } from './SharePulseSurveyModal/index';
import { InterviewSummary } from './InterviewSummary';
import { AlertObject, AlertType } from '../../components/Alert';
import { CandidateTabs } from './CandidateTabs';
import { ProfileTab } from './ProfileTab';
import { User } from '../../entities/User';
import { CandidateActions } from './CandidateActions';
import { CandidatePageHeader } from './CandidatePageHeader';
import { ApplicationService } from '../../services/ApplicationService';
import { InterviewListItem } from '../../entities/InterviewListItem';
import { JobHiringMemberRole } from '../../entities/JobHiringMemberRole';
import { ActivityTab } from './ActivityTab';
import { AccountIntegration } from '../../entities/AccountIntegration';
import { IconButton } from '../../components/IconButton';
import { CandidatePageSubHeader } from './CandidatePageSubHeader';
import { LoadingSpinner } from '../../components/LoadingSpinner';
import { FeedbackTab } from './FeedbackTab';
import { updateQueryParam } from '../../utils/url';

interface PropTypes {
  application: Application;
  atsSignableOfferEnabled: boolean;
  currentUser: User;
  interviewIntelligenceEnabled: boolean;
  referenceCheckEnabled: boolean;
  applicantTrackingEnabled: boolean;
  newApplicationPermitted: boolean;
  backgroundCheck: {
    enabled: boolean;
    integrationEnabled: boolean;
  };
  emailAccountIntegration: AccountIntegration;
  jobHiringMemberRole?: JobHiringMemberRole;
  applicationStylesPath: string;
  resumePanelFFEnabled: boolean;
  atsTagsPanelEnabled: boolean;
}

function defaultTab(
  interviewIntelligenceEnabled: boolean,
  applicantTrackingEnabled: boolean,
): CandidateTab {
  if (applicantTrackingEnabled) return 'profile';

  return interviewIntelligenceEnabled ? 'interviews' : 'success';
}

function CurrentTab(props: {
  tab: CandidateTab;
  currentUser: User;
  jobHiringMemberRole: JobHiringMemberRole;
  surveyRequest: SurveyRequest;
  referenceRequestStatus: ReferenceRequestStatus;
  application: Application;
  applicantTrackingEnabled: boolean;
  currentInterview?: InterviewListItem;
  onCurrentInterview: (interview: InterviewListItem) => void;
  backgroundCheck: {
    enabled: boolean;
    integrationEnabled: boolean;
  };
  setAlert: (alert: AlertObject) => void;
  reloadApplication: () => void;
  emailAccountIntegration: AccountIntegration;
  applicationStylesPath: string;
  setIsLoading: (v: boolean) => void;
  resumePanelFFEnabled: boolean;
  atsTagsPanelEnabled: boolean;
}) {
  switch (props.tab) {
    case 'profile':
      return (
        <ProfileTab
          application={props.application}
          currentUser={props.currentUser}
          emailAccountIntegration={props.emailAccountIntegration}
          jobHiringMemberRole={props.jobHiringMemberRole}
          reloadApplication={props.reloadApplication}
          setAlert={props.setAlert}
          applicationStylesPath={props.applicationStylesPath}
          setIsLoading={props.setIsLoading}
          resumePanelFFEnabled={props.resumePanelFFEnabled}
          atsTagsPanelEnabled={props.atsTagsPanelEnabled}
        />
      );
    case 'activity':
      return (
        <ActivityTab
          application={props.application}
          currentUser={props.currentUser}
          jobHiringMemberRole={props.jobHiringMemberRole}
          emailAccountIntegration={props.emailAccountIntegration}
          reloadApplication={props.reloadApplication}
          setAlert={props.setAlert}
          applicationStylesPath={props.applicationStylesPath}
        />
      );
    case 'feedback':
      return (
        <FeedbackTab
          application={props.application}
          currentUser={props.currentUser}
          jobHiringMemberRole={props.jobHiringMemberRole}
          reloadApplication={props.reloadApplication}
          setAlert={props.setAlert}
        />
      );
    case 'success':
      if (props.application.redacted) return null;

      return (
        <SuccessTab
          surveyRequest={props.surveyRequest}
          referenceRequestStatus={props.referenceRequestStatus}
        />
      );
    case 'interviews':
      if (props.application.redacted) return null;

      if (!props.currentInterview) {
        return (
          <InterviewsTab
            applicationId={props.application.id}
            applicantTrackingEnabled={props.applicantTrackingEnabled}
            currentUser={props.currentUser}
            application={props.application}
            jobHiringMemberRole={props.jobHiringMemberRole}
            onInterviewSelected={props.onCurrentInterview}
            setAlert={props.setAlert}
          />
        );
      } else {
        return (
          <InterviewSummary
            interview={props.currentInterview}
            atsEnabled={props.applicantTrackingEnabled}
            onBack={() => props.onCurrentInterview(null)}
          />
        );
      }
    case 'background_checks':
      if (props.application.redacted) return null;

      return (
        <BackgroundChecksTab
          application={props.application}
          applicantTrackingEnabled={props.applicantTrackingEnabled}
          integrationEnabled={props.backgroundCheck?.integrationEnabled}
        />
      );
  }
}

export default React.memo(function CandidatePageRouter(props: PropTypes) {
  return (
    <BrowserRouter>
      <CandidatePage {...props} />
    </BrowserRouter>
  );
});

const CandidatePage = React.memo(function CandidatePage(props: PropTypes) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [surveyRequest, setSurveyRequest] = useState<SurveyRequest>();
  const [referenceRequestStatus, setReferenceRequestStatus] =
    useState<ReferenceRequestStatus>('loading');
  const [isReferenceRequestStatusOpen, setIsReferenceRequestStatusOpen] =
    useState<boolean>(false);
  const [excludeReferencesWithIpWarning, setExcludeReferencesWithIpWarning] =
    useState<boolean>(false);
  const DEFAULT_TAB: CandidateTab = defaultTab(
    props.interviewIntelligenceEnabled,
    props.applicantTrackingEnabled,
  );
  const [application, setApplication] = useState<Application>(
    props.application,
  );
  const [isLoading, setIsLoading] = useState(false);

  const currentTab = (searchParams.get('tab') as CandidateTab) || DEFAULT_TAB;
  const [currentInterview, setCurrentInterview] =
    useState<InterviewListItem>(null);

  const handleTabClick = useCallback((tab: CandidateTab) => {
    return navigate(
      new URL(updateQueryParam(window.location.href, 'tab', tab)).search,
    );
  }, []);

  const loadSurveyRequests = () => {
    setReferenceRequestStatus('loading');
    SurveyRequestService.get(props.application.id)
      .then((surveyRequest) => {
        setSurveyRequest(surveyRequest);
        setReferenceRequestStatus(
          surveyRequest != null ? 'completed' : 'pending',
        );
      })
      .catch((e) => {
        if (e.status !== 404) {
          console.error(e);
        }
        setReferenceRequestStatus('pending');
      });
  };

  const createSurveyRequest = useCallback(async () => {
    setReferenceRequestStatus('submitting');
  }, [props.application.id]);

  const submitAskForReference = useCallback(async () => {
    try {
      setReferenceRequestStatus('success');
      await SurveyRequestService.create(props.application.id);
    } catch {
      setReferenceRequestStatus('failed');
    }
  }, [props.application.id]);

  const revertAskForReference = useCallback(async () => {
    setReferenceRequestStatus('pending');
  }, [props.application.id]);

  useEffect(() => {
    if (currentTab === 'success') {
      loadSurveyRequests();
    }
  }, [props.application.id, currentTab]);

  const filteredSurveyRequest = filterSurveyRequest(
    surveyRequest,
    excludeReferencesWithIpWarning,
  );

  const [openSharePulseSurveyModal, setOpenSharePulseSurveyModal] =
    useState<boolean>(false);
  const defaultAlert = { message: null, type: AlertType.Success };
  const [alert, setAlert] = useState<AlertObject>(defaultAlert);
  const reloadApplication = () => {
    ApplicationService.get(props.application.id).then((application) => {
      setApplication(application);
    });
  };

  const handleMoveCandidateToStage = (status: string) => {
    status === 'Success' && reloadApplication();
  };

  return (
    <>
      {isLoading && <LoadingSpinner showBackdrop />}
      <CandidatePageHeader
        alert={alert}
        application={application}
        applicantTrackingEnabled={props.applicantTrackingEnabled}
        setAlert={setAlert}
      >
        <CandidateActions
          application={application}
          atsSignableOfferEnabled={props.atsSignableOfferEnabled}
          currentTab={currentTab}
          interviewIntelligenceEnabled={props.interviewIntelligenceEnabled}
          currentUser={props.currentUser}
          sendOfferPermitted={application.sendOfferEnabled}
          newApplicationPermitted={props.newApplicationPermitted}
          jobHiringMemberRole={props.jobHiringMemberRole}
          applicantTrackingEnabled={props.applicantTrackingEnabled}
          emailAccountIntegration={props.emailAccountIntegration}
          setOpenSharePulseSurveyModal={setOpenSharePulseSurveyModal}
          onMoveCandidateStage={handleMoveCandidateToStage}
          setAlert={setAlert}
          reloadApplication={reloadApplication}
        />
      </CandidatePageHeader>
      <ReferenceRequestStatusModal
        onClose={() => setIsReferenceRequestStatusOpen(false)}
        isOpen={isReferenceRequestStatusOpen}
        surveyRequest={surveyRequest}
        excludeReferencesWithIpWarning={excludeReferencesWithIpWarning}
        onExcludeReferencesWithIpWarning={setExcludeReferencesWithIpWarning}
        referenceRequestStatus={referenceRequestStatus}
        setReferenceRequestStatus={setReferenceRequestStatus}
        loadSurveyRequests={loadSurveyRequests}
      />
      <ReferenceRequestConfirmationModal
        name={application.candidate.name}
        referenceRequestStatus={referenceRequestStatus}
        submitAskForReference={submitAskForReference}
        revertAskForReference={revertAskForReference}
      />
      <ReferenceRequestConfirmationAlert
        referenceRequestStatus={referenceRequestStatus}
        loadSurveyRequests={loadSurveyRequests}
      />
      <SharePulseSurveyModal
        isOpen={openSharePulseSurveyModal}
        onCancel={() => setOpenSharePulseSurveyModal(false)}
        onSubmit={() => {
          reloadApplication();
          setOpenSharePulseSurveyModal(false);
        }}
        applicationId={application.id}
        jobStageName={application.job_stage_name}
        candidateName={application.candidate.name}
      />
      <CandidateTabs
        currentTab={currentTab}
        handleTabClick={handleTabClick}
        applicantTrackingEnabled={props.applicantTrackingEnabled}
        interviewIntelligenceEnabled={props.interviewIntelligenceEnabled}
        referenceCheckEnabled={props.referenceCheckEnabled}
        backgroundCheckEnabled={props.backgroundCheck.enabled}
        redactedApplication={application.redacted}
      />
      {props.referenceCheckEnabled &&
        currentTab === 'success' &&
        !application.redacted && (
          <div className='d-flex mb-3 align-items-center'>
            <CandidatePageSubHeader text='Reference Checks' />
            <div className='ms-3 me-auto' data-testid='reporting-dates'>
              {filteredSurveyRequest && (
                <ReportDate surveyRequest={filteredSurveyRequest} />
              )}
            </div>
            <div className='d-flex align-items-center gap-3'>
              {referenceRequestStatus === 'completed' && (
                <>
                  <div className='d-inline-block fs-5'>
                    <ReferencesStatus surveyRequest={surveyRequest} />
                  </div>
                  <ViewStatusButton
                    surveyRequest={surveyRequest}
                    onClick={() => setIsReferenceRequestStatusOpen(true)}
                  />
                </>
              )}
              {referenceRequestStatus !== 'completed' && (
                <IconButton
                  buttonText='Request Reference Check'
                  color='secondary'
                  disabled={
                    !isAskForFeedbackEnabled(
                      application,
                      referenceRequestStatus,
                      props.applicantTrackingEnabled,
                    )
                  }
                  iconName='bi-file-earmark-arrow-up'
                  onClick={createSurveyRequest}
                />
              )}
              {referenceRequestStatus === 'completed' && (
                <ExportButton
                  surveyRequest={surveyRequest}
                  application={application}
                />
              )}
            </div>
          </div>
        )}
      <Routes>
        <Route
          path='applications/:id'
          element={
            <CurrentTab
              tab={currentTab}
              currentUser={props.currentUser}
              jobHiringMemberRole={props.jobHiringMemberRole}
              surveyRequest={filteredSurveyRequest}
              referenceRequestStatus={referenceRequestStatus}
              application={application}
              currentInterview={currentInterview}
              onCurrentInterview={setCurrentInterview}
              backgroundCheck={props.backgroundCheck}
              applicantTrackingEnabled={props.applicantTrackingEnabled}
              setAlert={setAlert}
              reloadApplication={reloadApplication}
              emailAccountIntegration={props.emailAccountIntegration}
              applicationStylesPath={props.applicationStylesPath}
              setIsLoading={setIsLoading}
              resumePanelFFEnabled={props.resumePanelFFEnabled}
              atsTagsPanelEnabled={props.atsTagsPanelEnabled}
            />
          }
        />
        <Route path='/*' element={<RedirectComponent />} />
      </Routes>
    </>
  );
});

/**
 * This component redirects to a URL outside the context of a (react-router)
 * Routes. It is not a regular component.
 */
function RedirectComponent() {
  window.location.replace(window.location.href);

  return null;
}

function filterSurveyRequest(
  surveyRequest: SurveyRequest | undefined,
  excludeReferencesWithIpWarning: boolean,
) {
  if (!surveyRequest || !excludeReferencesWithIpWarning) return surveyRequest;

  surveyRequest = JSON.parse(JSON.stringify(surveyRequest));

  for (const referral of surveyRequest.referrals) {
    referral.surveys = referral.surveys.filter(
      (survey) => survey.ip_matching_names.length < 1,
    );
  }

  return surveyRequest;
}

function isAskForFeedbackEnabled(
  application: Application,
  referenceRequestStatus: string,
  applicantTrackingEnabled: boolean,
) {
  return (
    (application.status === ApplicationStatus.Active ||
      application.status === ApplicationStatus.Hired) &&
    referenceRequestStatus === 'pending' &&
    application.candidate?.email?.length > 0 &&
    (!applicantTrackingEnabled || application.hasPlatformApplication)
  );
}
