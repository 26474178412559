import snakecaseKeys from 'snakecase-keys';
import { SignableOfferTemplate } from '../../../../entities/v1/applicant_tracking/SignableOfferTemplate';
import { SignableOfferTemplateList } from '../../../../entities/v1/applicant_tracking/SignableOfferTemplateList';
import { ApiService } from '../../../ApiService';

export interface UpsertParams {
  basedOnId?: number;
  name: string;
  daysToExpiration?: number;
  signatoryIds: number[];
  offerFile: FileParam;
  otherFiles: FileParam[];
  emailSubjectJson: string;
  emailSubjectPlain: string;
  emailBodyJson: string;
  emailBodyHtml: string;
}

export interface FileParam {
  // Either `id` is filled or `content`, never both.

  id?: number;
  fileName: string;
  content?: string;
}

export class SignableOfferTemplateService {
  public static list(
    expand: string[] = [],
  ): Promise<SignableOfferTemplateList> {
    return ApiService.get<SignableOfferTemplateList>(
      `/api/v1/applicant_tracking/signable_offer_templates`,
      snakecaseKeys({ expand }),
    );
  }

  public static show(
    id: number,
    expand: string[] = [],
  ): Promise<SignableOfferTemplate> {
    return ApiService.get<SignableOfferTemplate>(
      `/api/v1/applicant_tracking/signable_offer_templates/${id}`,
      snakecaseKeys({ expand }),
    );
  }

  public static create(params: UpsertParams): Promise<SignableOfferTemplate> {
    return ApiService.post<SignableOfferTemplate>(
      `/api/v1/applicant_tracking/signable_offer_templates`,
      snakecaseKeys(params),
    );
  }

  public static update(
    id: number,
    params: UpsertParams,
  ): Promise<SignableOfferTemplate> {
    return ApiService.patch<SignableOfferTemplate>(
      `/api/v1/applicant_tracking/signable_offer_templates/${id}`,
      snakecaseKeys(params),
    );
  }

  public static listVariables(file: string) {
    return ApiService.post<{ documentVariables: string[] }>(
      '/api/v1/applicant_tracking/signable_offer_templates/list_variables',
      snakecaseKeys({ file }),
    );
  }
}
