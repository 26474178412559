import React, { useEffect, useState } from 'react';
import { Chip, ChipColor } from '../../../../components/Chip';
import { Card } from '../../../../components/Card';
import { SignableOfferService } from '../../../../services/v1/applicant_tracking/SignableOfferService';
import { SignableOffer } from '../../../../entities/v1/applicant_tracking/SignableOffer';
import { Button } from 'reactstrap';
import { HorizontalDivider } from '../../../../components/HorizontallDivider';
import { SignableOfferSummaryModal } from './SignableOfferSummaryModal';
import { Application } from '../../../../entities/Application';
import { SpanTooltip } from '../../../../components/SpanTooltip';
import { SignableOfferDocuments } from './SignableOfferDocuments';

interface PropTypes {
  application: Application;
}

function OfferStatusChip(props: { status: string }) {
  switch (props.status) {
    case 'pending':
      return (
        <Chip size='sm' color={ChipColor.OrangeLight}>
          Processing
        </Chip>
      );
    case 'sent':
      return (
        <Chip size='sm' color={ChipColor.OrangeLight}>
          Pending
        </Chip>
      );
    case 'accepted':
      return (
        <Chip size='sm' color={ChipColor.SuccessLight}>
          Completed
        </Chip>
      );
    case 'revoked':
      return (
        <Chip size='sm' color={ChipColor.YellowLight}>
          Revoked
        </Chip>
      );
    case 'rejected':
      return (
        <Chip size='sm' color={ChipColor.DangerLight}>
          Declined
        </Chip>
      );
    case 'expired':
      return (
        <Chip size='sm' color={ChipColor.Gray}>
          Expired
        </Chip>
      );
  }
}

function ButtonWrapper(props: {
  disabled?: boolean;
  setModalOpen: (flag: boolean) => void;
}) {
  return (
    <Button
      disabled={props.disabled}
      onClick={() => props.setModalOpen(true)}
      color='borderless'
    >
      View Offer
    </Button>
  );
}

export function SignableOfferPanel(props: PropTypes) {
  const [signableOffer, setSignableOffer] = useState<SignableOffer>(null);
  const [modalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    (async () => {
      setSignableOffer(
        await SignableOfferService.last({
          applicationId: props.application.id,
        }),
      );
    })();
  }, [props.application]);

  if (signableOffer == null) {
    return null;
  }

  return (
    <Card className='gap-3'>
      <SignableOfferSummaryModal
        isOpen={modalOpen}
        signableOfferId={signableOffer.id}
        onClose={() => setModalOpen(false)}
      />
      <div className='d-flex justift-content-between'>
        <h3 className='mb-0 me-auto'>Offer</h3>
        <OfferStatusChip status={signableOffer.status} />
      </div>
      <SignableOfferDocuments
        offerStatus={signableOffer.status}
        signableOfferDocuments={signableOffer.signableOfferDocuments}
      />
      <HorizontalDivider />
      {signableOffer.status === 'pending' ? (
        <SpanTooltip
          className='text-center'
          text={<ButtonWrapper disabled={true} setModalOpen={setModalOpen} />}
          tooltipText='The offer submission is processing'
        />
      ) : (
        <ButtonWrapper setModalOpen={setModalOpen} />
      )}
    </Card>
  );
}
